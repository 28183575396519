<template>
  <div class="login-view">
    <div class="info">
      <div class="text">
        <p>Bem-vindo à área <br />de login administrativo</p>
      </div>
    </div>

    <div class="form">
      <div class="fields">
        <div class="col-12 mb-5">
          <div class="img-container col-11 mx-auto">
            <img class="img-logo" :src="require(`@/assets/img/logo.png`)" />
          </div>
        </div>

        <form @submit.prevent="login">
          <div class="form-fields">
            <div class="header" v-if="!isPasswordReset">
              <h1>Login</h1>

              <p>Entre com e-mail e senha</p>
            </div>

            <div class="header reset-info" v-if="isPasswordReset">
              <h1>Esqueci minha senha</h1>

              <p>
                Será enviado um e-mail para o endereço inserido com os próximos
                passos
              </p>
            </div>

            <b-field>
              <b-input
                v-model.trim="email"
                custom-class="center-text"
                placeholder="E-mail"
                type="email"
                autocomplete="email"
              ></b-input>
            </b-field>

            <b-field v-if="!isPasswordReset">
              <b-input
                v-model.trim="password"
                custom-class="center-text"
                placeholder="Senha"
                type="password"
              ></b-input>
            </b-field>
          </div>

          <div class="action-buttons" v-if="!isPasswordReset">
            <b-button
              :loading="isLoading"
              :disabled="isLoading"
              native-type="submit"
              type="is-primary"
              class="mt-5 login-button"
              expanded
              rounded
            >
              Entrar
            </b-button>

            <b-button
              @click="isPasswordReset = !isPasswordReset"
              type="is-text"
              class="mt-2 password-recovery"
              expanded
            >
              <span class="text-wrap">Esqueceu sua senha Clique aqui</span>
            </b-button>
          </div>

          <div class="action-buttons reset-button" v-if="isPasswordReset">
            <b-button
              @click="requestPasswordReset"
              :loading="isLoading"
              :disabled="isLoading"
              native-type="submit"
              type="is-primary"
              class="mt-5"
              expanded
              rounded
            >
              Enviar
            </b-button>

            <b-button
              @click="isPasswordReset = !isPasswordReset"
              type="is-text"
              class="mt-2 go-to-login"
              expanded
            >
              Fazer Login
            </b-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "@/store/actions/auth";
import UserService from "@/services/user.service";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    email: null,
    password: null,
    isLoading: false,
    isPasswordReset: false,
  }),
  computed: {
    ...mapGetters(["readOnly", "secondStage", "isFinancialUser"]),
  },
  methods: {
    login() {
      const { email, password } = this;

      if (!email?.trim() || !password?.trim()) return;

      this.isLoading = true;

      this.$store
        .dispatch(AUTH_REQUEST, { email, password })
        .then(() =>
          this.isFinancialUser
            ? this.$router.push("/referral-program")
            : this.$router.push("/lawyers")
        )
        .catch(() =>
          this.$buefy.snackbar.open("Dados inválidos. Tente novamente.")
        )
        .finally(() => (this.isLoading = false));
    },

    requestPasswordReset() {
      const { email } = this;
      this.isLoading = true;

      UserService.requestPasswordReset(email)
        .then(({ data }) => {
          this.$buefy.snackbar.open(data.message);
          this.isPasswordReset = !this.isPasswordReset;
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.img-container {
  height: 130px;

  .img-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.login-view {
  background: #fff;
  height: 100vh;

  display: flex;
  justify-content: space-between;
  overflow: hidden;
  font-size: clamp(0.5em, 1em, 1.3em);

  .span {
    font-size: clamp(0.5em, 0.9em, 1em);
  }

  .info {
    width: 70%;
    height: 100vh;
    padding-left: 50px;

    background: url("~@/assets/img/bg3.jpg");
    background-color: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    color: #fff;
    font-family: "Poppins";

    div {
      position: relative;
      top: 20%;

      .text {
        width: 100%;

        font-size: clamp(0.5em, 1.7em, 2.3em);
        color: #fff;
      }
    }
  }

  .form {
    width: 30%;
    height: 100vh;

    background: url("~@/assets/img/detalhe.png");
    background-color: #fff;
    background-position: 10vw 105%;
    background-repeat: no-repeat;
    background-size: contain;

    padding: 3%;

    .fields {
      height: 100%;
      // position: relative;
      top: 5vh;

      font-family: "Poppins";

      .header {
        margin-bottom: 30px;
        text-align: center;
        color: #182734;

        h1 {
          font-size: clamp(1.2em, 1.7em, 1.9em);
          font-weight: 700;
          margin-bottom: 10px;
        }

        p {
          font-style: italic;
          font-family: serif;
          font-size: clamp(0.5em, 1em, 1.5em);
        }
      }

      .reset-info {
        text-align: left;
        color: gray;

        p {
          font-size: clamp(0.5em, 1em, 1.5em);
          font-style: normal;
        }
      }

      .action-buttons {
        // position: relative;

        .password-recovery,
        .go-to-login {
          color: #182734;
          font-size: clamp(0.5em, 1em, 1.5em);
          text-decoration: none;
        }

        .password-recovery:focus {
          outline: none;
        }
      }

      .action-buttons.reset-button {
        top: 0;
      }
    }
  }
}

@media only screen and (max-width: 1280px) {
  .login-view {
    .info {
      div {
        .text {
          font-size: clamp(0.5em, 1em, 1.5em);
        }
      }
    }

    .form {
      .fields {
        .action-buttons {
          top: 40vh;
        }
      }
    }
  }
}

@media only screen and (max-width: 947px) {
  .login-view {
    .info {
      div {
        .text {
          font-size: clamp(0.5em, 1em, 1.5em);
        }
      }
    }
  }
}
</style>
