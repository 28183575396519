<template>
    <div class="dashboard-layout">
        <section class="menu-container" :class="{ show: showSidebar }">
            <b-sidebar class="mySidebar" position="static" type="is-primary" open>
                <div class="logo-ctn col-12">
                    <div class="col-11 mx-auto p-0" :style="{ height: showSidebar ? '110px' : '50px' }">
                        <img
                            :src="
                                require(`@/assets/img/${
                                    showSidebar ? 'logo_white.png' : 'logo_marca_white.png'
                                }`)
                            "
                            alt="logo Adv Easy com cores de tons azuis escuro perto da tonalidade de roxo"
                            srcset=""
                        />
                    </div>
                </div>

                <b-menu :activable="false">
                    <b-menu-list>
                        <router-link
                            tag="div"
                            v-for="(menu, index) in renderMenuItems"
                            :key="index"
                            :to="{ name: menu.routeName }"
                            :exact="menu.routeName === 'index'"
                        >
                            <b-menu-item
                                :class="{ 'menu-item-link': !showSidebar }"
                                :icon="menu.icon"
                                :label="showSidebar ? menu.label : null"
                            ></b-menu-item>
                        </router-link>

                        <div>
                            <b-menu-item
                                :class="{ 'menu-item-link': !showSidebar }"
                                @click="onLogoutClick()"
                                icon="sign-out-alt"
                                :label="showSidebar ? 'SAIR' : null"
                            ></b-menu-item>
                        </div>
                    </b-menu-list>
                </b-menu>
            </b-sidebar>
        </section>

        <section class="router-container custom-scroll">
            <router-view></router-view>
        </section>
    </div>
</template>

<script>
import MENUS from "@/constants/menu.constant";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import { mapGetters } from "vuex";

export default {
    data: () => ({
        menus: MENUS,
    }),

    computed: {
        renderMenuItems(){
            const availableItems = this.menus.filter((item) => {
                if(!this.isFinancialUser) {
                    return item;
                }

                if(this.isFinancialUser && item.routeName === 'referral-program.index') {
                    return item;
                }
            });

            return availableItems;
        },
        ...mapGetters(["showSidebar", "isFinancialUser"]),
    },

    methods: {
        onLogoutClick() {
            this.$buefy.dialog.confirm({
                message: "Deseja realmente sair?",
                onConfirm: () => this.logout(),
            });
        },

        logout() {
            this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push("/login"));
        },
    },
  
};
</script>

<style lang="scss">
div.dashboard-layout {
    display: flex;
    min-height: 100vh;
    background: var(--color-white);

    section.menu-container {
        min-height: 100vh;
        background: var(--color-dark);
        width: 50px;
        transition: width 0.3s ease-in-out;

        .menu-item-link {
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                margin-right: 0 !important;
                text-align: center;
            }
        }

        .sidebar-content {
            width: 50px;
            height: 100vh;
            position: fixed;
            overflow-y: auto;
            box-shadow: 0 2px 16px -4px rgba(0, 0, 0, 0.5);
            background: var(--color-secondary);
            transition: width 0.3s ease-in-out, opacity 0.5s;

            .logo-ctn {
                margin-bottom: 15px;
                padding: 0.6rem 0.2rem;
                background-color: var(--color-secondary);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .fixed-menu {
                background: #f9f9f9;
                font-size: 1rem;
                text-transform: uppercase;
            }

            .menu-label {
                padding-left: 0.6rem;
                color: #fff;
                text-transform: uppercase;
            }

            div.router-link {
                font-size: 1rem;
                text-transform: uppercase;
                align-items: center;
                vertical-align: middle;
            }

            div.router-link-active {
                > li {
                    background-color: #b48b0e;
                }

                > li a {
                    color: #fff;
                    font-weight: bolder;
                    font-size: 1rem;
                    text-transform: uppercase;
                }
            }

            li {
                padding: 0.3rem 0.3rem;

                &:hover {
                    background-color: #ffffff25;
                }
            }

            li a {
                color: #fff;
                font-size: 1rem;

                &:hover {
                    text-decoration: none;
                    background-color: transparent;
                }

                span:first-child {
                    margin-right: 0.5rem;
                    text-transform: uppercase;
                }
            }
        }

        &.show {
            width: 260px;
            display: block;

            .sidebar-content {
                width: 260px;
            }
        }
    }

    section.router-container {
        flex: 1;
        height: auto;
        min-height: 100vh;
        background: #f9f9f9;
        padding: 1rem;
        overflow-x: auto;
    }
}

@media only screen and (max-width: 1440px) {
    div.dashboard-layout {
        section.menu-container {
            direction: rtl;
            text-transform: uppercase;

            overflow: auto;
            .menu-label {
                direction: ltr;
            }
            .menu-list {
                direction: ltr;
            }
            &::-webkit-scrollbar-thumb {
                background: var(--color-primary);
            }

            &::-webkit-scrollbar {
                width: 8px;
                background-color: #f7f5f5;
            }

            .sidebar-content .pin {
                top: 2vh;
            }
        }
    }
}
</style>
